// import styled, { css } from 'styled-components'
// import tw from 'twin.macro' // eslint-disable-line import/no-extraneous-dependencies
// import { Link } from 'gatsby'
// import { LocalizedLink as Link } from 'gatsby-theme-i18n'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import {
  Layout,
  SectionBanner,
  SectionColumns,
  SectionHero,
  SectionImage,
  SectionText,
  SEO,
} from '../components'
import { SectionNewsletter } from '../components/Section/SectionNewsletter'
import Theme from '../components/theme'
import page from '../data/pages/sustainability.yml'

const Index = (): React.ReactElement => (
  <Theme>
    <SEO title={page?.title} />

    <div className="fixed z-0 w-full h-full top-0 left-0 opacity-10">
      <StaticImage
        src="../images/jellyfish.jpg"
        alt="Quallen"
        objectFit="cover"
        className="w-full h-full select-none"
        draggable={false}
      />
    </div>

    <Layout>
      <SectionHero
        title="Nachhaltigkeit als Zweck"
        titleTag="h1"
        subtitle="Zukunfsfähige Unternehmensgrundsätze für eine bessere Welt"
        text="Nachhaltiges Handeln ist der Treiber hinter payactive. Unsere Motivation und unser Anspruch ist es, ein Unternehmen aufzubauen, das einen Beitrag zu einer besseren Welt leistet. Wir definieren nachhaltiges Handeln dabei im Sinne der 17 Nachhaltigkeitsziele der UN (SDGs) und es umfasst verschiedene Ebenen von payactive. Zusammen bilden diese Ebenen unser Verständnis eines Unternehmens, das Verantwortung übernimmt - für sich selbst und für die Welt, in der es agiert."
      />

      <SectionText title="Aus Prinzip Verantwortung tragen" />
      <SectionColumns
        containerClass="py-0"
        data={[
          {
            text: 'payactive ist eine GmbH, die sich den Grundsätzen der Stiftung Verantwortungseigentum unterworfen hat. Die Stiftung arbeitet seit langem an einem neuen Verständnis für langfristig ausgerichtetes unternehmerisches Handeln. Wer sich ihren Grundsätzen unterwirft, trifft für sein Unternehmen eine nachhaltige. So sind die Grundsätze der Stiftung auch Kernbestandteil unserer Unternehmenssatzung. Zusätzlich hält die Stiftung einen Anteil von 1% an payactive, um im Zweifel als Sperrminorität agieren zu können. Anders ausgedrückt: Wir können nicht mehr zurück, selbst wenn wir es wollten. ',
          },
          {
            text: '**Aber was bedeutet Verantwortungseigentum eigentlich?** Im Kern will die Idee eine Renaissance von autonomen Unternehmen schaffen, die langfristig geführt werden. Die Logik des Verantwortungseigentums geht davon aus, dass die, die ein Unternehmen führen, es selbstbestimmt führen sollten. Stimmrechte und Kontrolle bleiben hier also ausschließlich bei Menschen, die dem Unternehmen praktisch verbunden sind. Darüber hinaus ist Vermögen an das Unternehmen gebunden. Gewinne sind immer nur Mittel zum Zweck. Sie sind im Sinne des Verantwortungseigentums nicht dazu gedacht, abgeschöpft oder an andere Unternehmen transferiert zu werden - Kosten natürlich ausgenommen. In der Satzung von payactive haben wir uns zur Unterstützung nachhaltiger Projekte verpflichtet. Einen großen Teil unserer Gewinne spenden wir also an gute Projekte weiter.',
          },
        ]}
      />

      <SectionImage
        image={
          <StaticImage
            src="../images/team-im-gruenen-arme-hoch.jpg"
            alt="Büro"
            className="shadow-xl rounded-lg my-10"
          />
        }
      />

      <SectionText title="Social Cashback: Mit Zahlungen Impact schaffen" />
      <SectionColumns
        containerClass="py-0"
        data={[
          {
            text: 'Es gibt diese Ideen, die so einfach sind, dass sie jeder versteht. Selbst wenn man sich als weder besonders technik- noch finanzaffin bezeichnen würde. payactive Social Cashback ist eine dieser Ideen. Sie bildet einen der wesentlichen Pfeiler für unseren nachhaltigen Beitrag. Und sie erlaubt uns, die Spenden unseres Unternehmens mit unseren Finanz-Services zu verknüpfen. Nachhaltigkeit wird mit Social Cashback zum Kernbestandteil unseres Finanzprodukts. Und: Social Cashback kann als nachhaltiges Tool von unseren Kund:innen genutzt werden. Denn dank Social Cashback verleihen wir dir und deinem Unternehmen die nötige Flexibilität, um Zahlungsprozesse über payactive mit deinen Nachhaltigkeitszielen zu verbinden.',
          },
          {
            text: '**Wie funktioniert Social Cashback?** Ganz einfach. Bei jeder finanziellen Online-Transaktion fallen Transaktionskosten an. Dabei handelt es sich meist um wenige Cent, die für einzelne Kund:innen kaum ins Gewicht fallen. Sie gehen an die Zahlungsplattform, die damit bezahlt wird, um den Finanztransfer und die dahinter stehende Infrastruktur zu finanzieren. So auch bei payactive. Wenn du als Kund:in unsere Zahlungsplattform einbindest, um damit zum Beispiel Überweisungen oder Lastschriften smart und digital abzuwickeln, gehen die dort anfallenden Transaktionskosten an payactive. Nach Abzug der Kosten werden diese an ein nachhaltiges Projekt (im Sinne der SDGs der UN) gespendet. Eine simple Idee mit großem Impact. So stellen wir uns modernes, verantwortungsvolles Handeln vor.',
          },
        ]}
      />

      <SectionImage
        image={
          <StaticImage
            src="../images/woman-leaf.jpg"
            alt="Frau mit Blatt"
            className="shadow-xl rounded-lg my-10"
          />
        }
      />

      <SectionText title="Nachhaltigkeitstools für unsere Kund:innen" />
      <SectionColumns
        containerClass="py-0"
        data={[
          {
            text: 'payactive begreift regeneratives Handeln nicht "nur" als Thema für unsere eigenen Grundsätze. Die payactive Payment Plattform ist auch mit Social Cashback so aufgebaut, dass unsere Kund:innen damit selbst ein starkes Tool für nachhaltiges Wirtschaften geboten bekommen. Wir wollen andere befähigen, selbst aktiv zu werden. Und wir glauben daran, dass die Lösung für die Probleme unserer Welt in vielen kleinen automatisierten Handlungen liegt, die meist mehr Impact haben als die grünste Kampagne, die dann nach zwei Wochen endet. Hier bietet payactive gleich mehrere Ideen, damit unsere Kund:innen ihre Nachhaltigkeitsziele mit unserer Payment Plattform verbinden, monitoren und kommunizieren können. Denn wo sonst schafft das unselige Begleichen von Rechnungen noch gute Lösungen für unsere Welt? Eben.',
          },
          {
            text: '**Was bedeutet das genau?** Tools wie Social Cashback können von dir und künftig auch von deinen Kund:innen mit variablen Spendenzielen belegt werden. Natürlich variieren die Leitlinien der Nachhaltigkeit von Unternehmen zu Unternehmen. Manches Unternehmen arbeitet mit Partnern, wie zum Beispiel Umweltschutz-Organisationen oder Vereinen zur Inklusion, zur Sporthilfe oder zur Unterstützung von Mädchen in der Dritten Welt zusammen. payactive erlaubt dir, deine Partner:innen mit unserer Payment Plattform zu verknüpfen und die Ergebnisse an deine Kund:innen zu kommunizieren. Wir arbeiten mit dir zusammen, um transparent alle Daten zu liefern und darzulegen, wie viel Geld Du und deine Kund:innen über die payactive Payment Plattform generiert hast. So unterstützen wir dich bei der Kommunikation nachhaltiger Erfolge gegenüber deinen eigenen Kund:innen. Wie hieß der alte PR-Grundsatz noch? "Tue Gutes und rede darüber."',
          },
        ]}
      />

      <SectionImage
        image={
          <StaticImage
            src="../images/starry-night.jpg"
            alt="Nacht"
            className="shadow-xl rounded-lg my-10"
          />
        }
      />

      <SectionBanner
        title="Challenge uns"
        text="Wir glauben, dass wir eine extrem spannende Zahlungslösung für dich erdacht haben. Testen uns. Lass dir die payactive Plattform zeigen. Natürlich völlig unverbindlich."
        subtext="Manuel Born, Sales-Expert von payactive"
        image={
          <StaticImage
            src="../images/manuwithstars.png"
            alt="Person mit Glitzer"
            style={{ maxWidth: 450 }}
          />
        }
        actionLinks={[
          {
            label: 'Termin buchen',
            url: 'termin-buchen',
          },
          {
            label: 'Per E-Mail kontaktieren',
            url: 'mailto:hello@payactive.eu?subject=Request for information',
            variant: 'link',
          },
        ]}
      />

      <SectionNewsletter containerClass="" page="Nachhaltigkeit" />
    </Layout>
  </Theme>
)

export default Index
